import { useTranslation } from 'react-i18next'
import { CubeTransparentIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { useEffect, useState } from 'react'
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form'
import UpdateCategoryRequest from '@/business/dto/requests/update-category.request'
import {
  createCategory,
  updateCategoryById,
} from '@/business/api/category.service'
import { calcColor } from '@/business/api/image.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiImageUpload from '../ui-kit/input/basic/UiImageUpload'
import UiInput from '../ui-kit/input/basic/UiInput'
import { useNavigate } from 'react-router-dom'
import CreateCategoryRequest from '@/business/dto/requests/create-category.request'
import UiColorPicker from '../ui-kit/input/UiColorPicker'
import Color from '@/business/dto/types/color'
import CategoryResponse from '@/business/dto/responses/category.response'

const mapToFormValue = (
  category?: CategoryResponse
): CreateCategoryRequest | UpdateCategoryRequest => {
  return {
    name: category?.name || '',
    description: category?.description || '',
    image: category?.image || null,
    color: category?.color || Color.gray,
    position: category?.position || undefined,
  }
}

export const CategoryCreateCard = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateCategoryRequest>({
    defaultValues: mapToFormValue(undefined),
  })
  const name = form.watch('name')

  useEffect(() => {
    form.setValue('color', calcColor(name))
  }, [name])

  const onSubmit: SubmitHandler<CreateCategoryRequest> = (data) => {
    setLoading(true)
    createCategory(data)
      .then((response) => {
        navigate(`/categories/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.created')}
            description={t('toast.description.categoryCreated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CategoryCreateUpdateCard loading={loading} category={undefined} />
      </form>
    </FormProvider>
  )
}

export type CategoryUpdateCardProps = {
  category: CategoryResponse
}

export const CategoryUpdateCard = (props: CategoryUpdateCardProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCategoryRequest>({
    defaultValues: mapToFormValue(props.category),
  })

  const onSubmit: SubmitHandler<UpdateCategoryRequest> = (data) => {
    setLoading(true)
    updateCategoryById(props.category._id, {
      ...data,
    })
      .then((response) => {
        navigate(`/categories/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.categoryUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        handleValidationError(err, i18n)
        setLoading(false)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CategoryCreateUpdateCard loading={loading} category={props.category} />
      </form>
    </FormProvider>
  )
}

type CategoryCreateUpdateCardProps = {
  loading: boolean
  category?: CategoryResponse
}

const CategoryCreateUpdateCard = (props: CategoryCreateUpdateCardProps) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const form = useFormContext<CreateCategoryRequest | UpdateCategoryRequest>()

  return (
    <UiCard>
      {/* {false && (
        <UiCard.Header
          title={'General'}
          description={t('page.categories.properties.description')}
          icon={CubeTransparentIcon}
        />
      )} */}
      <UiCard.Body>
        <div className='space-y-6'>
          <UiImageUpload
            label={t('common.image')}
            avatar={{
              name: form.watch('name')!,
              color: form.watch('color')!,
              shape: 'rounded',
            }}
            name='image'
            control={form.control}
          />
          <UiInput
            label={t('common.name')}
            type='text'
            name='name'
            control={form.control}
            autoFocus={true}
            rules={{
              required: {
                value: true,
                message: t('form.validation.required'),
              },
              minLength: {
                value: 3,
                message: t('form.validation.minLength', { x: 3 }),
              },
              maxLength: {
                value: 32,
                message: t('form.validation.maxLength', { x: 32 }),
              },
            }}
          />
          <UiInput
            label={t('common.description')}
            type='text'
            description='Schreibe eine kurze Beschreibung, die unter dem Namen erscheint. Hierbei kann es sich um eine Jobbezeichnung handeln.'
            name='description'
            control={form.control}
            rules={{
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          <UiColorPicker
            label={t('common.color')}
            control={form.control}
            name='color'
            rules={{ required: true }}
          />
          <hr className='bg-gray-100' />
          {props.category && (
            <UiInput
              label={t('common.position')}
              type='number'
              name='position'
              control={form.control}
              rules={{
                valueAsNumber: true,
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              }}
            />
          )}
        </div>
      </UiCard.Body>
      <UiCard.Footer>
        <UiButton
          label={t('action.cancel')}
          type='button'
          variant='flat'
          onClick={
            props.category
              ? () => navigate(`/categories/${props.category?._id}`)
              : () => navigate('/categories')
          }
        />
        <UiButton
          label={props.category ? t('action.save') : t('action.create')}
          type='submit'
          variant={props.category ? 'dark' : 'primary'}
          loading={props.loading}
          disabled={!form.formState.isDirty}
        />
      </UiCard.Footer>
    </UiCard>
  )
}
