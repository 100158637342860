import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { findServiceById } from '@/business/api/service.service'
import ServiceCategoriesAddCard from '@/components/modals/ServiceCategoriesAddCard'
import { findAllCategories } from '@/business/api/category.service'
import Paginated from '@/business/dto/responses/paginated.response'
import ServiceResponse from '@/business/dto/responses/service.response'
import CategoryResponse from '@/business/dto/responses/category.response'

export const serviceCategoriesAddPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [service, paginatedCategories] = await Promise.all([
    findServiceById(params.serviceId!),
    findAllCategories({ page: 1, limit: 10 }),
  ])

  return defer({ service, paginatedCategories })
}

const ServiceCategoriesAddPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { service, paginatedCategories } = useLoaderData() as {
    service: ServiceResponse
    paginatedCategories: Paginated<CategoryResponse>
  }

  return (
    <UiContainer size='small'>
      <UiHeader
        title={t('page.title.serviceCategoriesAdd')}
        description={t('page.description.serviceCategoriesAddX', {
          x: service.name,
        })}
        desktopBackHref={`/services/${service._id}`}
        mobileBackHref={`/services/${service._id}`}
      />
      <div className='py-6 md:py-8'>
        <div className='space-y-6'>
          <ServiceCategoriesAddCard
            service={service}
            paginatedCategories={paginatedCategories}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ServiceCategoriesAddPage
