import {
  Control,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import UiCombobox from './basic/UiCombobox'
import * as ct from 'countries-and-timezones'
import { useTranslation } from 'react-i18next'

type UiTimezoneComboboxProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  label?: string | null
  disabled?: boolean
  className?: string

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
}

const UiTimezoneCombobox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiTimezoneComboboxProps<TFieldValues, TName>
) => {
  const nowInUtc = new Date()
  const { t, i18n } = useTranslation()
  const timezones = Object.values(ct.getAllTimezones())

  return (
    <UiCombobox
      {...props}
      options={timezones.map((timezone) => {
        return {
          label: `${timezone.name} (${
            new Intl.DateTimeFormat(i18n.language, {
              timeZoneName: 'shortOffset',
              timeZone: timezone.name,
            })
              .formatToParts(nowInUtc)
              .filter((e) => e.type === 'timeZoneName')[0].value
          })`,
          value: timezone.name,
          timezone,
        }
      })}
    />
  )
}

export default UiTimezoneCombobox
