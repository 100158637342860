import {
  Control,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Country from '@/business/dto/types/country'
import UiCombobox from './basic/UiCombobox'
import * as ct from 'countries-and-timezones'

type UiCountryComboboxProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  label?: string | null
  disabled?: boolean
  className?: string

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
}

const UiCountryCombobox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiCountryComboboxProps<TFieldValues, TName>
) => {
  const { t, i18n } = useTranslation()
  const countries = Object.values(ct.getAllCountries())

  return (
    <UiCombobox
      {...props}
      options={countries.map((country) => {
        return {
          label:
            new Intl.DisplayNames([i18n.language], { type: 'region' }).of(
              country.id
            ) || '',
          value: country.id,
        }
      })}
    />
  )
}

export default UiCountryCombobox
