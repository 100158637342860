import { useTranslation } from 'react-i18next'
import {
  CheckCircleIcon,
  CubeTransparentIcon,
} from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { findAllServices } from '@/business/api/service.service'
import { useState } from 'react'
import Paginated from '@/business/dto/responses/paginated.response'
import { handleValidationError } from '@/business/error-handler'
import { AxiosError } from 'axios'
import { updateCategoryById } from '@/business/api/category.service'
import UiSearch from '../ui-kit/input/UiSearch'
import classNames from 'classnames'
import UiAvatar from '../ui-kit/layout/UiAvatar'
import UiEmptyState from '../ui-kit/presentation/UiEmptyState'
import UiNoSearchResults from '../ui-kit/presentation/UiNoSearchResults'
import UiPagination from '../ui-kit/presentation/UiPagination'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import CategoryResponse from '@/business/dto/responses/category.response'
import ServiceResponse from '@/business/dto/responses/service.response'

const mapToFormValue = (
  category: CategoryResponse
): { services: { id: string }[] } => {
  return {
    services:
      category.serviceIds
        ?.map((id) => ({ id }))
        .sort((a, b) => a.id.localeCompare(b.id)) || [],
  }
}

type CategoryServiceAddCardProps = {
  category: CategoryResponse
  paginatedServices: Paginated<ServiceResponse>
}

const CategoryServicesAddCard = (props: CategoryServiceAddCardProps) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [paginated, setPaginated] = useState<Paginated<ServiceResponse> | null>(
    props.paginatedServices
  )
  const [search, setSearch] = useState<string>('')
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<{ services: { id: string }[] }>({
    defaultValues: mapToFormValue(props.category),
  })
  const watchServices = form.watch('services')

  const reload = (page: number, search: string) => {
    findAllServices({
      page,
      limit: 10,
      search,
    })
      .then((response) => {
        setLoadingTimeout(null)
        setPaginated(response)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handleSearchChanged = (search: string) => {
    setSearch(search)

    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
    }

    setLoadingTimeout(setTimeout(() => reload(1, search), 200))
  }

  const onSubmit: SubmitHandler<{ services: { id: string }[] }> = (data) => {
    setLoading(true)
    updateCategoryById(props.category._id, {
      serviceIds: data.services.map((s) => s.id),
    })
      .then((response) => {
        navigate(`/categories/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.categoryUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <UiCard>
        {/* {false && (
          <UiCard.Header
            title={'General'}
            description={t('page.contacts.properties.description')}
            icon={CubeTransparentIcon}
          />
        )} */}
        <UiCard.Body>
          {paginated && watchServices && (
            <>
              <UiSearch
                value={search}
                onChanged={(e) => handleSearchChanged(e.target.value)}
                placeholder={t('form.placeholder.searchCategory')}
                loadingTimeout={loadingTimeout}
              />
              <ul role='list' className='-my-2 space-y-1'>
                {paginated.results.map((service) => (
                  <li key={service._id} className='flex items-center gap-x-4'>
                    <button
                      type='button'
                      className={classNames(
                        'relative flex items-center space-x-4 w-full pl-3 pr-9 py-2 rounded-md border',
                        watchServices.some((s) => s.id === service._id)
                          ? `bg-${auth.company.appearance.color}-100 border-${auth.company.appearance.color}-600`
                          : 'hover:bg-gray-100 border-transparent'
                      )}
                      onClick={() => {
                        if (watchServices.some((s) => s.id === service._id)) {
                          form.setValue(
                            'services',
                            watchServices
                              .filter((s) => s.id !== service._id)
                              .sort((a, b) => a.id.localeCompare(b.id)),
                            { shouldDirty: true }
                          )
                          return
                        } else {
                          form.setValue(
                            'services',
                            [...watchServices, { id: service._id }].sort(
                              (a, b) => a.id.localeCompare(b.id)
                            ),
                            { shouldDirty: true }
                          )
                        }
                      }}
                    >
                      <div className='flex-shrink-0'>
                        <UiAvatar
                          name={service.name}
                          color={service.color}
                          image={service.image}
                          shape='rounded'
                          size={10}
                        />
                      </div>
                      <div className='min-w-0 flex-1 text-left'>
                        <p className='truncate text-sm font-medium text-gray-900'>
                          {service.name}
                        </p>
                        <p className='truncate text-sm text-gray-500'>
                          {service.duration} {t('common.minutes')},{' '}
                          {service.price}
                        </p>
                      </div>
                      {watchServices.some((s) => s.id === service._id) && (
                        <span
                          className={`absolute inset-y-0 right-0 flex items-center pr-3 text-${auth.company.appearance.color}-600`}
                        >
                          <CheckCircleIcon
                            className='h-6 w-6'
                            aria-hidden='true'
                          />
                        </span>
                      )}
                    </button>
                  </li>
                ))}
              </ul>
              {paginated.totalResults === 0 && !paginated.search && (
                <UiEmptyState
                  icon={CubeTransparentIcon}
                  title={`Es sind keine ${t('common.services')} verfügbar`}
                  description={`Bei einer ${t(
                    'common.service'
                  )} handelt es sich um ...`}
                />
              )}
              {paginated.totalResults === 0 && paginated.search && (
                <UiNoSearchResults />
              )}
              {paginated.totalPages > 1 && (
                <div className='flex justify-center'>
                  <UiPagination
                    page={paginated.page}
                    totalPages={paginated.totalPages}
                    onPageChanged={(page: number) => reload(page, search)}
                  />
                </div>
              )}
            </>
          )}
        </UiCard.Body>
        <UiCard.Footer>
          <UiButton
            label={t('action.cancel')}
            type='button'
            variant='flat'
            onClick={() => navigate(`/categories/${props.category?._id}`)}
          />
          <UiButton
            label={t('action.save')}
            type='submit'
            variant='dark'
            loading={loading}
            disabled={!form.formState.isDirty}
          />
        </UiCard.Footer>
      </UiCard>
    </form>
  )
}

export default CategoryServicesAddCard
