import { useEffect, useState } from 'react'
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import { EnvelopeIcon, KeyIcon } from '@heroicons/react/24/outline'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import SignInRequest from '@/business/dto/requests/sign-in.request'
import { signIn } from '@/business/api/auth.service'
import UiInput from '@/components/ui-kit/input/basic/UiInput'
import { useTranslation } from 'react-i18next'
import { updatePageTitle } from '@/business/utils'
import { handleValidationError } from '@/business/error-handler'
import PublicLayout from '@/components/PublicLayout'

const SignInPage = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect') || '/'
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<SignInRequest>({
    defaultValues: {
      email: searchParams.get('email') || (location.state as any)?.email || '',
      password: '',
    },
  })
  const [formEmail] = form.watch(['email'])

  useEffect(() => {
    updatePageTitle(t('page.title.signIn'))
  }, [])

  const onSubmit: SubmitHandler<SignInRequest> = (data) => {
    setLoading(true)
    signIn(data)
      .then((response) => {
        localStorage.setItem('token_type', response.tokenType)
        localStorage.setItem('access_token', response.accessToken)
        localStorage.setItem('refresh_token', response.refreshToken)
        navigate(redirect, { replace: true })
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.signedIn')}
            description={t('toast.description.signedIn')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        console.log('err', err)
        if (err.response?.status === 400) {
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type='error'
              title={t('toast.title.signedInError')}
              description={t('toast.description.invalidCredentials')}
            />
          ))
        } else if (err.response?.status === 403) {
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type='error'
              title={t('toast.title.signedInError')}
              description={t('toast.description.noSubscription')}
            />
          ))
        } else if (err.response?.status === 420) {
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type='error'
              title={t('toast.title.signedInError')}
              description={t('toast.description.notVerified')}
            />
          ))
          navigate({
            pathname: '/verify',
            search: `?email=${encodeURIComponent(formEmail)}`,
          })
        } else {
          handleValidationError(err, i18n)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img
          className='mx-auto h-6 w-auto'
          src='/img/resflow-logo.svg'
          alt={t('common.resflowLogo')!}
        />
        <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
          {t('page.title.signIn')}
        </h2>
        <p className='mt-2 text-center text-base text-gray-600'>
          {t('page.description.signIn')}
        </p>
      </div>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white px-6 shadow rounded-lg py-8 sm:px-10'>
          <form className='space-y-6' onSubmit={form.handleSubmit(onSubmit)}>
            <UiInput
              label={t('common.emailAddress')}
              type='text'
              icon={EnvelopeIcon}
              placeholder={t('form.placeholder.emailAddress')!}
              autoComplete='email'
              name='email'
              autoFocus={!(location.state as any)?.email}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t('form.validation.emailAddress'),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              }}
            />
            <UiInput
              label={t('common.password')}
              type='password'
              icon={KeyIcon}
              placeholder={t('form.placeholder.password')!}
              autoComplete='password'
              name='password'
              autoFocus={!!(location.state as any)?.email}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                minLength: {
                  value: 8,
                  message: t('form.validation.minLength', { x: 8 }),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              }}
            />
            <div className='text-xs'>
              <Link
                className='font-medium text-rose-600 hover:text-rose-700'
                to='/forgot-password'
                state={{
                  name: (location.state as any)?.name || '',
                  email: formEmail,
                }}
              >
                {t('action.forgotPassword')}
              </Link>
            </div>
            <div>
              <button
                type='submit'
                className='group relative flex w-full justify-center items-center rounded-full border border-transparent bg-rose-600 disabled:bg-rose-600/50 py-2 px-4 text-sm font-medium leading-6 text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2'
                disabled={loading}
              >
                <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                  <LockClosedIcon
                    className='h-5 w-5 text-rose-400 group-hover:text-rose-500 group-disabled:text-rose-400/50'
                    aria-hidden='true'
                  />
                </span>
                {loading && (
                  <div className='-ml-1 mr-3'>
                    <svg
                      className='animate-spin h-5 w-5 text-white'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      ></path>
                    </svg>
                  </div>
                )}
                {t('action.signIn')}
              </button>
            </div>
          </form>
          <div className='mt-6 text-center'>
            <p className='text-xs text-gray-600'>
              <span>{t('description.dontHaveAnAccount')} </span>
              <Link
                to='/sign-up'
                state={{
                  name: (location.state as any)?.name || '',
                  email: formEmail,
                }}
                className='font-medium text-rose-600 hover:text-rose-700'
              >
                {t('action.goToSignUp')}
                <span aria-hidden='true'> &rarr;</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignInPage
