import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import {
  CubeTransparentIcon,
  FaceSmileIcon,
  PlusSmallIcon,
} from '@heroicons/react/24/outline'
import { handleValidationError } from '@/business/error-handler'
import Paginated from '@/business/dto/responses/paginated.response'
import { findResourceServices } from '@/business/api/resource.service'
import useAuth from '@/contexts/use-auth'
import UiAvatar from '@/components/ui-kit/layout/UiAvatar'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiPagination from '@/components/ui-kit/presentation/UiPagination'
import UiEmptyState from '@/components/ui-kit/presentation/UiEmptyState'
import UiNoSearchResults from '@/components/ui-kit/presentation/UiNoSearchResults'
import { Link } from 'react-router-dom'
import ResourcePopulatedResponse from '@/business/dto/responses/resource-populated.response'
import ServiceResponse from '@/business/dto/responses/service.response'

const loadPage = (resourceId: string, page: number) =>
  findResourceServices(resourceId, { page, limit: 10 })

// export const resourceServicesLoader = async (args: LoaderFunctionArgs) =>
//   loadPage(args.params.resourceId!, 1)

export type ResourceServicesCardProps = {
  resource: ResourcePopulatedResponse
  setResource: React.Dispatch<React.SetStateAction<ResourcePopulatedResponse>>
  paginatedServices: Paginated<ServiceResponse>
  setPaginatedServices: React.Dispatch<
    React.SetStateAction<Paginated<ServiceResponse>>
  >
}

const ResourceServicesCard = (props: ResourceServicesCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  const reload = (page: number) => {
    loadPage(props.resource._id, page)
      .then((response) => {
        props.setPaginatedServices(response)
        window.scrollTo(0, 0)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handlePageChanged = (page: number) => {
    reload(page)
  }

  return (
    <>
      <UiCard>
        <UiCard.Header
          title={`Zugewiesene ${t('common.services')} (${
            props.paginatedServices.totalResults
          })`}
          description={t('page.description.resourceServices')}
          icon={FaceSmileIcon}
        >
          <>
            {(props.paginatedServices.totalResults > 0 ||
              props.paginatedServices.search) && (
              <>
                <UiButton
                  as={Link}
                  label={`${t('common.service')} zuweisen`}
                  variant='default'
                  icon={PlusSmallIcon}
                  to={`/resources/${props.resource._id}/services`}
                />
              </>
            )}
          </>
        </UiCard.Header>
        <UiCard.Body>
          <div className='space-y-6 md:space-y-8'>
            <ul role='list' className='-my-2'>
              {props.paginatedServices.results.map((service) => (
                <li key={service._id} className='py-2'>
                  <div className='flex items-center space-x-4'>
                    <div className='flex-shrink-0'>
                      <UiAvatar
                        name={service.name}
                        color={service.color}
                        image={service.image}
                        shape='rounded'
                        size={10}
                      />
                    </div>
                    <div className='min-w-0 flex-1'>
                      <p className='truncate text-sm font-medium text-gray-900'>
                        {service.name}
                      </p>
                      <p className='truncate text-sm text-gray-500'>
                        {service.duration} {t('common.minutes')},{' '}
                        {service.price}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {props.paginatedServices.totalPages > 1 && (
              <div className='flex justify-center w-full'>
                <UiPagination
                  page={props.paginatedServices.page}
                  totalPages={props.paginatedServices.totalPages}
                  onPageChanged={handlePageChanged}
                />
              </div>
            )}
            {props.paginatedServices.totalResults === 0 &&
              !props.paginatedServices.search && (
                <UiEmptyState
                  icon={CubeTransparentIcon}
                  title={`Es wurden noch keine ${t(
                    'common.services'
                  )} hinzugefügt`}
                  description={`Bei einer ${t(
                    'common.service'
                  )} handelt es sich um ...`}
                  button={{
                    label: `${t('common.service')} zuweisen`,
                    to: `/resources/${props.resource._id}/services`,
                  }}
                />
              )}
            {props.paginatedServices.totalResults === 0 &&
              props.paginatedServices.search && <UiNoSearchResults />}
          </div>
        </UiCard.Body>
        <UiCard.Footer />
      </UiCard>
      {/* <ResourceAddServiceModal
        resource={props.resource}
        open={open}
        setOpen={setOpen}
        onUpdated={(resource: Resource) => {
          props.setResource(resource)
          reload(1)
        }}
      /> */}
    </>
  )
}

export default ResourceServicesCard
